<template>
  <BaseList
    :headers="headers"
  >
    <template #item="{item}">
      <ListItem :item="item"/>
    </template>
  </BaseList>
</template>

<script>
import headers from './list/headers'
import BaseList from '@/components/base/BaseList'
import ListItem from './list/ListItem'

export default {
  name: 'Constructor',
  components: { BaseList, ListItem },
  data () {
    return {
      headers: headers
    }
  }
}
</script>

<style scoped>

</style>
