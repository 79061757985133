import { translate } from '@/messages'

export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('constructor.object'),
    align: 'start',
    value: 'entityName'
  },
  {
    text: translate('constructor.style'),
    align: 'start',
    value: 'icon'
  },
]
